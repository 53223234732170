export const production = process.env.NODE_ENV === 'production'

export const myTelegramLink = 'https://t.me/dontRepeatYourself'
export const HOST = production ? 'https://fsa.my' : 'http://192.168.0.33:3000'

export enum Tags {
  CONTENT='Content',
  JQUER='JQuery',
  JOOMLA='Joomla',
  WEBASYST='Webasyst',
  VUEJS='VueJS',
  ANIMATION='Animation',
  BACKAND='Backand',
  FRONTAND='Frontend',
  REACT='ReactJS',
  MOBIX='Mobix',
  STATEMANAGMENT='State Managment',
  DASHBOARD='DashBoard',
  CLIENTSERVER='Client Server App',
  MAKETS='By Makets',
  BL='Buisness Logic',
  NODEJS='NodeJs',
  DOCKER='Docker',
  PWA='PWA',
  REACTNATIVE='React Native',
  ANALITICS='Analitics',
}
